import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'

import * as styles from './About.module.css'

export default function About() {
    const { t } = useTranslation()

    return (
        <Row className={styles.container}>
            <Col xs={12} md={{ span: 6, offset: 3 }}>
                <h2>{t('about.title')}</h2>
                <h4>{t('about.subTitle')}</h4>
                <p>{t('about.text')}</p>
            </Col>
        </Row>
    )
}
