import React from 'react'
import { Col, Row } from 'react-bootstrap'
import LanguageSelector from '../shared/LanguageSelector/LanguageSelector'

import { ReactComponent as Logo } from '../../images/new-logo.svg'
import * as styles from './Header.module.css'

export default function Header() {
    return (
        <div className={styles.header}>
            <Row className="h-100">
                <Col xs={6} className="h-100">
                    <div className="d-flex align-items-center justify-content-start h-100 ml-md-3">
                        <Logo className={styles.logo} />
                    </div>
                </Col>
                <Col xs={6} className="h-100">
                    <div className="d-flex align-items-center justify-content-end h-100 mr-md-5">
                        <LanguageSelector />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
