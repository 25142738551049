import React, { Suspense } from 'react'
import './config/i18n'
import { Container } from 'react-bootstrap'
import Header from './components/Header/Header'
import ProjectCard from './components/ProjectCard/ProjectCard'
import About from './components/About/About'
import Footer from './components/Footer/Footer'
import Divider from './components/shared/Divider/Divider'

import dataJSON from './data/data.json'

const App = () => (
    <Container fluid>
        <Suspense fallback={null}>
            <Header />
            {dataJSON.projects.map((project) => (
                <ProjectCard project={project} key={project.id} />
            ))}
            <About />
            <Divider color="white" />
            <Divider color="black" />
            <Footer />
        </Suspense>
    </Container>
)

export default App
