import PropTypes from 'prop-types'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Divider from '../shared/Divider/Divider'
import ActionBar from './ActionBar'
import * as styles from './ProjectCard.module.css'

export default function ProjectCard({ project }) {
    const { t } = useTranslation()
    const { id, isWelcomeCard, hasButton, image, hasSocial } = project

    const cardTitle = isWelcomeCard ? (
        <h2 className={`align-self-start ${styles.yellowTitle}`}>
            {t(`project.${id}.title`)}
        </h2>
    ) : (
        <h2 className="align-self-start">{t(`project.${id}.title`)}</h2>
    )

    return (
        <>
            <Row>
                <Col
                    lg={6}
                    xs={12}
                    className={`d-flex flex-column align-items-center justify-content-center ${styles.container}`}
                >
                    <h6 className="align-self-start">
                        {t(`project.${id}.type`)}
                    </h6>
                    <h4 className="align-self-start">
                        {t(`project.${id}.subtitle`)}
                    </h4>
                    {cardTitle}
                    <div>
                        <p>{t(`project.${id}.subtitle.description`)}</p>
                        <p>{t(`project.${id}.description`)}</p>
                    </div>
                    <ActionBar
                        id={id}
                        hasButton={hasButton}
                        hasSocial={hasSocial}
                    />
                </Col>
                <Col lg={6} xs={12} className="p-0">
                    <div className={`${styles.background} ${styles[image]}`} />
                </Col>
            </Row>
            <Divider color="white" />
        </>
    )
}

ProjectCard.propTypes = {
    project: PropTypes.shape({
        id: PropTypes.string,
        isWelcomeCard: PropTypes.bool,
        hasButton: PropTypes.bool,
        hasSocial: PropTypes.bool,
        image: PropTypes.string,
    }).isRequired,
}
