import React from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './LanguageSelector.module.css'

const LanguageSelector = () => {
    const { i18n } = useTranslation()

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.dataset.lng)
    }

    return (
        <div className={`${styles.container}`}>
            <button
                type="button"
                data-lng="it"
                onClick={changeLanguage}
                className={i18n.language === 'it' ? styles.activate : ''}
            >
                ITA
            </button>
            <button
                type="button"
                data-lng="en"
                onClick={changeLanguage}
                className={i18n.language === 'en' ? styles.activate : ''}
            >
                ENG
            </button>
        </div>
    )
}

export default LanguageSelector
