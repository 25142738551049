import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'

import * as styles from './Divider.module.css'

export default function Divider({ color }) {
    return (
        <Row
            className={`${styles.container} ${styles[color]} d-none d-lg-block`}
        />
    )
}

Divider.propTypes = {
    color: PropTypes.oneOf(['white', 'black']).isRequired,
}
