import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

import * as socialButtonStyles from './SocialButton.module.css'

export default function SocialButton({ facebook, instagram }) {
    return (
        <div className={socialButtonStyles.container}>
            {facebook && (
                <a
                    href={facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                >
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
            )}
            {instagram && (
                <a
                    href={instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                >
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
            )}
        </div>
    )
}

SocialButton.propTypes = {
    facebook: PropTypes.string,
    instagram: PropTypes.string,
}

SocialButton.defaultProps = {
    facebook: undefined,
    instagram: undefined,
}
