import React from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
// import pdf from '../../pdf/campaign-ita.pdf'
import SocialButton from '../shared/SocialButton/SocialButton'
import * as styles from './Footer.module.css'

export default function Footer() {
    const { t } = useTranslation()

    return (
        <Row>
            <div className={styles.container}>
                {/* <a
                    href={pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.pdf}
                >
                    pdf
                </a> */}
                <div className="align-self-center my-4">
                    <SocialButton facebook="https://www.facebook.com/tirelessmonkey" />
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-around p-2 my-4 text-center">
                    <div>
                        <p>
                            {t('footer.contact.label')}:{' '}
                            {t('footer.contact.email')}
                        </p>
                    </div>
                    <div>
                        <p>{`${new Date().getFullYear()} ${t(
                            'footer.copy'
                        )}`}</p>
                    </div>
                </div>
            </div>
        </Row>
    )
}
