import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../shared/Button/Button'
import SocialButton from '../shared/SocialButton/SocialButton'
import pdfIta from '../../pdf/urban-trash-ita-preview.pdf'
import pdfEng from '../../pdf/urban-trash-eng-preview.pdf'
import pdfItaCastagneDiMartorana from '../../pdf/castagne-di-martorana-preview.pdf'

export default function ActionBar({ id, hasButton, hasSocial }) {
    const { t, i18n } = useTranslation()

    const socialAndButtonAligned =
        hasSocial && hasButton
            ? 'justify-content-between'
            : 'justify-content-end'

    const pdfUrban = i18n.language === 'it' ? pdfIta : pdfEng
    const pdf = id === 'two' ? pdfItaCastagneDiMartorana : pdfUrban

    return (
        <div
            className={`d-flex align-items-center w-100 ${socialAndButtonAligned} px-md-2 px-lg-4`}
        >
            {hasButton && (
                <Button text={t(`project.${id}.button`)} link={pdf} />
            )}
            {hasSocial && (
                <SocialButton
                    facebook={t(`project.${id}.facebook`)}
                    instagram={t(`project.${id}.instagram`)}
                />
            )}
        </div>
    )
}

ActionBar.propTypes = {
    id: PropTypes.string.isRequired,
    hasButton: PropTypes.bool.isRequired,
    hasSocial: PropTypes.bool.isRequired,
}
