import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './Button.module.css'

export default function Button({ text, link }) {
    return (
        <a
            href={link}
            className={`align-self-md-end align-self-sm-center ${styles.button}`}
            target="_blank"
            rel="noreferrer"
        >
            {text}
        </a>
    )
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
}
